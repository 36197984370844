export function mergeValues(target, value) {
  if (typeof target === 'undefined' || target === null) {
    return value;
  }
  if (Array.isArray(target)) {
    return target.concat(Array.isArray(value) ? value : [value]);
  } else if (typeof target === 'object') {
    return { ...target, ...toObject(value) };
  }
}

/**
 * Forces value to the object instance
 * @param value
 * @returns {Object<string, any>}
 */
function toObject(value) {
  if (typeof value === 'undefined' || value === null) {
    return {};
  }
  if (Array.isArray(value)) {
    try {
      return Object.fromEntries(value);
    } catch (e) {
      return Object.fromEntries(Object.keys(value).map((key, i) => [key, value[i]]));
    }
  } else if (typeof value === 'object') {
    return value;
  } else {
    return {};
  }
}

export function isEmpty(val) {
  if (typeof val === 'undefined' || val === null) {
    return true;
  }
  if (val instanceof File) {
    return false;
  }
  if (Array.isArray(val) || typeof val === 'string') {
    return val.length === 0;
  }
  if (typeof val === 'object') {
    return Object.values(val).filter((val) => !isEmpty(val)).length === 0;
  }
  return false;
}

/**
 * @param {Object} conditions
 * @return {Object}
 */
export function clearEmptyFilters(conditions) {
  const entries = Object.entries(conditions).filter(([k, v]) => {
    if (k === 'features') {
      try {
        const val = JSON.parse(v);
        return !isEmpty(val);
      } catch (e) {
        return false;
      }
    }
    return !k.startsWith('__') && !isEmpty(v);
  });
  return Object.fromEntries(entries);
}
