import Dataman from 'App/Dataman';
import { ROLE_ADMIN, ROLE_STAFF } from '@/app/constants/Roles';

export async function loadUsers(params) {
  const res = await Dataman.DB.users.getList(params, { ld: false });
  console.log(res);
  return res.data.map(({ id, name }) => ({ text: name, value: id }));
}

export function loadStaff(params) {
  return loadUsers({
    properties: ['id', 'name'],
    itemsPerPage: 100,
    'roles.title': [ROLE_STAFF, ROLE_ADMIN].map((role) => role.toUpperCase()),
    'order[name]': 'asc',
    ...params,
  });
}

export async function searchStaff(term, params = {}) {
  return loadStaff({ name: term, ...params });
}
