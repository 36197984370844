import Vue from 'vue';
import Vuex from 'vuex';
import Dataman from 'App/Dataman';
import InterfaceAPI from 'App/Interface';
import UserModel from 'Models/User';
import UserboxAPI from 'App/Userbox';
import { DEFAULT_LANGUAGE } from 'App/constants/trans';
import { persist as persistValue, restore, timePoint } from 'App/utils/Helpers';
import { CACHED_DATA_CATEGORIES } from '@/app/utils/Collections';

Vue.use(Vuex);

const User = new UserModel(Dataman.User);
const Userbox = new UserboxAPI();
const Interface = new InterfaceAPI();

Dataman.setErrorHandler((err) => {
  if (err?.response?.status === 401) {
    Dataman.cancelRequests();
    setTimeout(() => {
      User.checkAuthToken().then(() => {
        User.logout().then(() => {
          Interface.reloadApp();
        });
      });
    }, 2000);
  } else {
    Interface.CatchError = err + ' | ' + typeof err;
  }
});
Dataman.refreshTokenHandler(async (tokens) => {
  await User.auth(tokens);
});

const DEF_REPORTER_STATE = {
  entity: null,
  items: [],
};
const DEF_COUNTERS_CACHE = {
  counters: null,
  expireAt: null,
};
const DEF_STAFF_STATS = {
  period: 0,
  custom: { from: null, to: null },
};
const DEF_RECENTLY_ADDED = {
  counters: null,
  items: null,
  lastUpdate: null,
};
const DEF_FILTER_EDITOR = {
  id: null,
  category: null,
  item: null,
  key: null,
  path: null,
};
const DEF_USER_SESSIONS = { loaded: false, list: [], activeId: null };

const store = new Vuex.Store({
  state: {
    flags: {
      loaded: false,
      isAdmin: false,
    },
    user: User,
    Interface,
    Userbox,
    reporter: restore('reporter', { ...DEF_REPORTER_STATE }),
    priceRequests: restore('priceRequests', [0, 0, 0]),
    currentRequest: null,
    countersCache: DEF_COUNTERS_CACHE,
    recentlyAdded: restore('recentlyAdded', { ...DEF_RECENTLY_ADDED }),
    modules: restore('modules', {}),
    lang: restore('lang', DEFAULT_LANGUAGE),
    filters: restore('filters', {}),
    infoSourceData: null,
    staffStats: restore('staffStats', { ...DEF_STAFF_STATS }),
    cachedData: restore('cachedData', {}),
    vars: restore('vars', {}),
    columnsFilter: restore('columnsFilter', {}),
    visitedItems: restore('history', []),
    isOrderCreatedFlag: false,
    forwardBackwardRedirectEntityData: {
      page: 1,
      pages: 1,
      itemsPerPage: 30,
      category: '',
      items: [],
      link: null,
      filter: null,
    },
    filterEditor: restore('filterEditor', { ...DEF_FILTER_EDITOR }),
    appTabs: new Map(restore('appTabs', [])),
    userSessions: { ...DEF_USER_SESSIONS },
    featuredSessionDismissed: restore('featuredSessionDismissed', false),

    // Integration API - https://fdc-api.winglegroup.com/
    ingredientsAndNutrients: {
      foodByGtinCode: null,
      isLoading: false,
      errorLoad: '',
    },
  },
  mutations: {
    changeForwardBackwardRedirectEntityData(state, payload) {
      state.forwardBackwardRedirectEntityData = payload;
    },
    changeIsOrderCreatedFlag(state, payload) {
      state.isOrderCreatedFlag = payload;
    },
    addVisitedItem(state, item) {
      state.visitedItems.push(item);
      persistValue('history', state.visitedItems);
    },
    setVar(state, data) {
      state.vars = { ...state.vars, ...(data || {}) };
      persistValue('vars', state.vars);
    },
    removeVar(state, key) {
      if (key in state.vars) {
        delete state.vars[key];
        persistValue('vars', state.vars);
      }
    },
    logout(state) {
      state.flags = { loaded: false, isAdmin: false };
      state.reporter = { ...DEF_REPORTER_STATE };
      state.recentlyAdded = { ...DEF_RECENTLY_ADDED };
      state.filters = {};
      state.infoSourceData = null;
      state.staffStats = { ...DEF_STAFF_STATS };
      state.featuredSessionDismissed = false;
      persistValue('recentlyAdded', state.recentlyAdded);
      persistValue('reporter', state.reporter);
      persistValue('filters', state.filters);
      persistValue('staffStats', state.staffStats);
      persistValue('staffStats', state.featuredSessionDismissed);
    },
    setPriceRequested(state) {
      if (state.currentRequest !== null) {
        const requests = [...state.priceRequests];
        requests[state.currentRequest] = 1;
        persistValue('priceRequests', requests);
        state.priceRequests = requests;
        state.currentRequest = null;
      }
    },
    setCurrentRequest(state, key) {
      state.currentRequest = key;
    },
    setFlag(state, { flag, value }) {
      state.flags[flag] = value;
    },
    setReporter(state, { items, entity }) {
      state.reporter.entity = entity || null;
      state.reporter.items = items.map((el) => +el);
      persistValue('reporter', state.reporter);
    },
    updCounters(state, data) {
      state.countersCache = { counters: data, lastUpdate: Date.now() };
      persistValue('countersCache', state.countersCache);
    },
    updRecentlyAdded(state, { type, data }) {
      state.recentlyAdded[type] = data;
      if (type === 'counters') {
        state.recentlyAdded.lastUpdate = Date.now();
        state.recentlyAdded.items = null;
      }
      persistValue('recentlyAdded', state.recentlyAdded);
    },
    clearRecentlyAdded(state) {
      state.recentlyAdded = { ...DEF_RECENTLY_ADDED };
      persistValue('recentlyAdded', state.recentlyAdded);
    },
    setModules(state, data) {
      state.modules = { ...data };
      persistValue('modules', state.modules);
    },
    setLang(state, lang) {
      state.lang = lang;
      persistValue('lang', state.lang);
    },
    setFilter(state, { key, data, persist }) {
      state.filters[key] = data;
      if (persist !== false) {
        persistValue('filters', state.filters);
      }
    },
    setInfoSourceData(state, val) {
      state.infoSourceData = val;
    },
    setStaffStats(state, payload) {
      state.staffStats = { ...state.staffStats, ...payload };
      persistValue('staffStats', state.staffStats);
    },
    setCachedData(state, payload) {
      const { key, data, lifetime } = payload;
      state.cachedData = {
        ...state.cachedData,
        [key]: { data, lifetime, expireAt: timePoint(lifetime) },
      };
      persistValue('cachedData', state.cachedData);
    },
    clearCachedData(state, key) {
      const data = { ...state.cachedData };
      const keys = Array.isArray(key) ? key : [key];
      for (const key of keys) {
        delete data[key];
        console.log(`Removed: ${key}`);
      }
      state.cachedData = data;
      persistValue('cachedData', state.cachedData);
    },
    setColumnsFilter(state, payload) {
      const { path, config } = payload || {};
      state.columnsFilter[path] = config;
      persistValue('columnsFilter', state.columnsFilter);
    },
    setFilterEditor(state, payload) {
      const { category, item, key, path } = payload || {};
      state.filterEditor = { id: item?.id || null, category, item, key, path };
      persistValue('filterEditor', state.filterEditor);
    },
    setTab(state, payload) {
      const { uid } = payload;
      state.appTabs.set(uid, payload);
      persistValue('appTabs', [...state.appTabs.entries()]);
    },
    updateTab(state, payload) {
      const { uid, ...data } = payload;
      const tab = state.appTabs.get(uid);
      if (tab) {
        Object.assign(tab, data);
      } else {
        state.appTabs.set(uid, payload);
      }
      persistValue('appTabs', [...state.appTabs.entries()]);
    },
    updateTabProp(state, payload) {
      const { uid, key, value } = payload;
      const tab = state.appTabs.get(uid);
      tab[key] = value;
      persistValue('appTabs', [...state.appTabs.entries()]);
    },
    removeTab(state, uid) {
      state.appTabs.delete(uid);
      persistValue('appTabs', [...state.appTabs.entries()]);
    },
    clearAllTabs(state) {
      state.appTabs = new Map();
      persistValue('appTabs', [...state.appTabs.entries()]);
    },
    setUserSessions(state, payload) {
      state.userSessions.list = payload;
      state.userSessions.loaded = true;
    },
    pushUserSession(state, payload) {
      state.userSessions.list.push(payload);
    },
    updateUserSession(state, payload) {
      const idx = state.userSessions.list.findIndex((el) => el.id === payload.id);
      if (idx === -1) {
        state.userSessions.list.push(payload);
      } else {
        state.userSessions.list[idx] = payload;
      }
    },
    setActiveUserSession(state, payload) {
      state.userSessions.activeId = payload;
    },
    dismissFeaturedSession(state, payload) {
      state.featuredSessionDismissed = !!payload;
      persistValue('featuredSessionDismissed', state.featuredSessionDismissed);
    },

    // ingredientsAndNutrients state
    setFoodByGtinCode(state, payload) {
      state.ingredientsAndNutrients.foodByGtinCode = payload;
    },
    setIsLoadingFoodByGtinCode(state, payload) {
      state.ingredientsAndNutrients.isLoading = payload;
    },
    setErrorLoadFoodByGtinCode(state, payload) {
      state.ingredientsAndNutrients.errorLoad = payload;
    },
    clearFoodByGtinCode(state) {
      state.ingredientsAndNutrients.foodByGtinCode = null;
    },
  },
  actions: {
    logout({ commit }) {
      commit('logout');
    },
    priceRequest({ commit }, key) {
      commit('setCurrentRequest', key);
    },
    setRequested({ commit }) {
      commit('setPriceRequested');
    },
    setIsAdmin({ commit }, state) {
      commit('setFlag', { flag: 'isAdmin', value: !!state });
    },
    setFlag({ commit }, { flag, state }) {
      commit('setFlag', { flag, value: !!state });
    },
    setReporter({ commit }, { items, entity = null }) {
      commit('setReporter', { items, entity });
    },
    setCounters({ commit }, data) {
      commit('updCounters', data || {});
    },
    updCounter({ commit, state }, { category, value }) {
      commit('updCounters', {
        ...(state.countersCache.counters || {}),
        [category]: value,
      });
    },
    setRecentCounters({ commit }, data) {
      commit('updRecentlyAdded', { type: 'counters', data });
    },
    setRecentItems({ commit, state }, data) {
      const current = state.recentlyAdded.items || {};
      commit('updRecentlyAdded', {
        type: 'items',
        data: { ...current, ...data },
      });
    },
    setModules({ commit }, data) {
      commit('setModules', data || {});
    },
    setLang({ commit }, lang) {
      commit('setLang', lang);
    },
    updateFilter({ commit, state }, { key, data, persist }) {
      const exists = state.filters[key] || {};
      commit('setFilter', { key, data: { ...exists, ...data }, persist });
    },
    setFilter({ commit }, payload) {
      commit('setFilter', payload);
    },
    clearFilter({ commit }, key) {
      commit('setFilter', { key, data: null });
    },
    setInfoSourceUrl({ commit }, url) {
      commit('setInfoSourceData', { url, loading: false });
    },
    setInfoSourceLoading({ commit, state }, loading) {
      commit('setInfoSourceData', {
        ...(state.infoSourceData || {}),
        loading: !!loading,
      });
    },
    clearInfoSourceData({ commit }) {
      commit('setInfoSourceData', null);
    },
    setStaffStatsFilter({ commit }, { period, custom }) {
      const payload = { period };
      if (custom) {
        payload.custom = custom;
      }
      commit('setStaffStats', payload);
    },
    sendAction() {
      /* noop */
    },
    provideHeaders() {
      /* noop */
    },
    filterColumns({ commit }, payload) {
      commit('setColumnsFilter', payload);
    },
    editUserFilter({ commit }, payload) {
      commit('setFilterEditor', payload);
    },
    unsetFilterEditor({ commit }) {
      commit('setFilterEditor', { ...DEF_FILTER_EDITOR });
    },
    updateTab({ commit }, payload) {
      commit('updateTab', payload);
    },
    // ingredientsAndNutrients state
    async loadFoodByGtinCode({ commit }, gtinCode) {
      commit('setFoodByGtinCode', null);
      commit('setErrorLoadFoodByGtinCode', '');
      commit('setIsLoadingFoodByGtinCode', true);

      try {
        const data = await Dataman.DB.ingredientsAndNutrients.getFoodByGTIN(gtinCode);

        if (data) {
          commit('setFoodByGtinCode', data.data);
        }
      } catch (error) {
        commit('setErrorLoadFoodByGtinCode', 'Food not found');
        return;
      } finally {
        commit('setIsLoadingFoodByGtinCode', false);
      }
    },
  },
  getters: {
    currentPlan(state) {
      return state.currentRequest;
    },
    isLoggedIn() {
      return User.logged;
    },
    token() {
      return User.token;
    },
    fullyLoaded(state) {
      return state.flags.loaded;
    },
    counters(state) {
      return state.countersCache.counters || {};
    },
    builtAt(state) {
      return state.vars.buildTimestamp;
    },
    filtersEditorState(state) {
      return state.filterEditor.id !== null;
    },
    activeUserSession(state) {
      return state.userSessions.activeId || null;
    },
    readableCats(state) {
      return state.cachedData[CACHED_DATA_CATEGORIES]?.data?.readable || [];
    },

    // ingredientsAndNutrients state
    foodByGtinCode(state) {
      return state.ingredientsAndNutrients.foodByGtinCode;
    },
    isLoadingFoodByGtinCode(state) {
      return state.ingredientsAndNutrients.isLoading;
    },
    foodNutrients(state) {
      return state.ingredientsAndNutrients.foodByGtinCode?.foodNutrients || [];
    },
    errorLoad(state) {
      return state.ingredientsAndNutrients.errorLoad;
    },
  },
});

export default store;

export const useStore = () => store;
