import BaseModel from '@/models/BaseModel';
import moment from 'moment';

/**
 * @class BaseModel
 * @prop {number} id
 * @prop {string} searchString
 * @prop {string} searchRule
 * @prop {number} lastResult
 * @prop {Object} conditions
 * @prop {string} path
 * @prop {number} module
 * @prop {string} createdAt
 * @prop {string} updatedAt
 * @prop {string} category
 */
export default class UserSession extends BaseModel {
  constructor(props = {}) {
    super(props);
  }

  get uid() {
    return this.props.conditions?.__uid || null;
  }

  get date() {
    const date = moment(this.props.updatedAt || this.props.createdAt);
    return date.format('YYYY-MM-DD HH:mm:ss');
  }

  get dateFromNow() {
    const date = moment(this.props.updatedAt || this.props.createdAt);
    return date.fromNow();
  }

  get moduleName() {
    return this.props.category;
  }

  get page() {
    return this.props.conditions?.__page;
  }

  get category() {
    return this.props.category;
  }
}
